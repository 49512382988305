import styled from "styled-components";

import { getFontFamily } from "@braze/beacon-styling";

export const Prompt = styled.div`
  font-family: ${getFontFamily("base")};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
